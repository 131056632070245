@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'),
    url('../fonts/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSansMedium';
  src: local('WorkSansMedium'),
    url('../fonts/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSansSemiBold';
  src: local('WorkSansSemiBold'),
    url('../fonts/WorkSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSansBold';
  src: local('WorkSansBold'),
    url('../fonts/WorkSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSansExtraBold';
  src: local('WorkSansExtraBold'),
    url('../fonts/WorkSans-ExtraBold.ttf') format('truetype');
}
