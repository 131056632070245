@import './assets/styles/fonts.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  h1 {
    font-size: 36px;
    font-family: 'WorkSansSemiBold';
  }
  h2 {
    font-size: 30px;
    font-family: 'WorkSansSemiBold';
  }
  h3 {
    font-size: 24px;
    font-family: 'WorkSansSemiBold';
  }
  h4 {
    font-size: 20px;
    font-family: 'WorkSansSemiBold';
  }
  h5 {
    font-size: 18px;
    font-family: 'WorkSansSemiBold';
  }
  h6 {
    font-size: 16px;
    font-family: 'WorkSansSemiBold';
  }
}

body {
  font-family: 'WorkSans';
}

@media (min-width: 640px) {
  body {
    background-color: #f3f2f1;
  }
  #root {
    background-color: #fff;
    margin: 0 auto;
    width: 80%;
    height: 100vh;
    overflow: auto;
    max-width: 600px;
  }
}

@media (min-width: 1024px) {
  #root {
    margin: 0 auto;
    width: 30%;
  }
}
